




























































































































































































































































































































import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@/services-v2/contact.service";
import { receiveItemsService } from "@/services-v2/receive-item.service";
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
import { masterTypeService } from "@/services-v2/master-type.service";
import Vue from "vue";
import { ResponsePagination } from "@/models/interface/common.interface";
import moment from "moment";
import printJS from "print-js";
import { receiveItemChecklistService } from "@/services-v2/receive-item-checklist.service";
import { ResponseListReceivingItemChecklist } from "@/models/interface-v2/receiving-item-checklist.interface";
import localStorageService from "@/services/localStorage.service";
import { IAuthorities } from "@/models/interface-v2/auth.interface";
import { IResponseListGoodReceipt } from "@/models/interface-v2/receiving-item.interface";
import { ResponseListContactData } from "@/models/interface-v2/contact.interface";
import { ResponseListMasterType } from "@/models/interface-v2/master.interface";
import { GR_LOCAL_STORAGE, GR_STATUS } from "@/models/enums/good-receipt.enum";
import {generalJournalServices} from "@service/general-journal.service";
import {debounceProcess} from "@helper/debounce";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";

export default Vue.extend({
  name: "ListGoodReceipt",
  components: {
    CSelectBranch: () => import(/*webpackPrefetch: true*/"@/components/shared/select-branch/CSelectBranch.vue"),
  },
  data() {
    this.getListSupplier = debounceProcess(this.getListSupplier, 200);
    return {
      GR_STATUS,
      formRules: {
        supplierName: {
          label: "lbl_supplier_name",
          name: "supplierName",
          placeholder: "lbl_type_here",
          decorator: [
            "supplierName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        poNumber: {
          label: "lbl_po_number",
          name: "poNumber",
          placeholder: "lbl_type_here",
          decorator: [
            "poNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        goodReceiptDateFrom: {
          label: "lbl_good_receipt_date_from",
          name: "goodReceiptDateFrom",
          placeholder: "lbl_type_here",
          decorator: [
            "goodReceiptDateFrom",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        goodReceiptDateTo: {
          label: "lbl_good_receipt_date_to",
          name: "goodReceiptDateTo",
          placeholder: "lbl_type_here",
          decorator: [
            "goodReceiptDateTo",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        goodReceiptNumber: {
          label: "lbl_good_receipt_number",
          name: "good_receipt_number",
          placeholder: "lbl_type_here",
          decorator: [
            "good_receipt_number",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        status: {
          label: "lbl_status",
          name: "status",
          placeholder: "lbl_choose",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
      formModel: {
        supplierName: "",
        poNumber: "",
        goodReceiptNumber: "",
        goodReceiptDateFrom: "",
        goodReceiptDateTo: "",
        status: "",
        branchName: "",
      },
      dtSupplier: {} as ResponseListContactData,
      DEFAULT_DATE_FORMAT,
      dtStatus: [] as {
        key: number,
        value: string,
        id: string,
        name: string
      }[],
      dtGoodReceiptPurchasing: {} as IResponseListGoodReceipt,
      grParam: {
        limit: DEFAULT_PAGE_SIZE,
        page: 0,
        sorts: "createdDate:desc"
      } as RequestQueryParamsModel,
      tableFilter: {} as RequestQueryParamsModel,
      dtGoodReceiptWarehouse: {} as ResponseListReceivingItemChecklist,
      userPrivileges: [] as IAuthorities[],
      loading: {
        find: false,
        download: false
      }
    };
  },
  computed: {
    formWrapper() {
      return {
        labelCol: {
          sm: {
            span: 24
          },
          md: {
            span: 12
          }
        },
        wrapperCol: {
          sm: {
            span: 24
          },
          md: {
            span: 12
          }
        }
      };
    },
    hasPrivilegeGRWarehouse(): boolean | undefined {
      return this.userPrivileges.find(x => x.key === "good-receipt-warehouse")?.key === "good-receipt-warehouse";
    },
    ableToCreateGRWarehouse(): boolean | undefined {
      return !!this.userPrivileges.find(x => x.key === "good-receipt-warehouse" && x.privilege.create);
    },
    ableToUpdateGRWarehouse(): boolean | undefined {
      return !!this.userPrivileges.find(x => x.key === "good-receipt-warehouse" && x.privilege.update);
    },
    hasPrivilegeInventoryReceive(): boolean | undefined {
      return this.userPrivileges.find(x => x.key === "inventory-receive")?.key === "inventory-receive";
    },
    ableToUpdateGRPurchase(): boolean | undefined {
      return !!this.userPrivileges.find(x => x.key === "inventory-receive" && (x.privilege.create || x.privilege.update));
    },
    /**
     * defined table source
     * based on role
     */
    tableSource(): ResponseListReceivingItemChecklist | IResponseListGoodReceipt {
      const { dtGoodReceiptPurchasing, dtGoodReceiptWarehouse } = this;
      let val: IResponseListGoodReceipt | ResponseListReceivingItemChecklist = {
        currentPage: 0,
        data: [],
        totalElements: 0,
        totalPages: 0
      };

      if (this.hasPrivilegeGRWarehouse && this.hasPrivilegeInventoryReceive) {
        val = dtGoodReceiptPurchasing;
      } else if (this.hasPrivilegeGRWarehouse) {
        val = dtGoodReceiptWarehouse;
      } else if (this.hasPrivilegeInventoryReceive) {
        val = dtGoodReceiptPurchasing;
      }

      return val;
    }
  },
  created() {
    this.userPrivileges = localStorageService.loadUserPrivilege();
  },
  mounted() {
    this.getListSupplier();
    this.getListStatus();
    this.requestDtSource(this.grParam);
  },
  methods: {
    moment,
    async handleClickColumn(journalNumber) {
      let data;
      const paramsJournal = {
        page: 0,
        limit: 10,
        search: `name~${journalNumber}`,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      data = await generalJournalServices.listGeneralJournal(paramsJournal);
      if (data.data.length > 0) {
        this.$confirm({
          title: "You will be redirected to another page, are you sure?",
          onOk: () => {
            this.$router.push(`/generaljournal/journal/detail/${data.data[0].id}`);
          },
          onCancel() {
            return;
          },
        });
      }
    },
    getListSupplier(search = ""): void {
      const params: RequestQueryParamsModel = {
        limit: DEFAULT_PAGE_SIZE,
        page: 0,
        search: "supplier~true_AND_active~true",
        sorts: "firstName:asc"
      };
      if (search) params.search = `supplier~true_AND_active~true_AND_firstName~*${search}*`;
      contactServices
      .listContactData(params)
      .then((res) => {
        this.dtSupplier = res;
      });
    },
    sortStatus(str: string): number {
      if (str === GR_STATUS.WAIT_FOR_APPROVAL) {
        return 1;
      } else if (str === GR_STATUS.UNBILLED) {
        return 2;
      } else if (str === GR_STATUS.BILLED) {
        return 3;
      } else if (str === GR_STATUS.CANCEL) {
        return 4;
      } else if (str === GR_STATUS.RETURNED) {
        return 5;
      }
      return 0;
    },
    getListStatus(): void {
      const params: RequestQueryParamsModel = {
        name: "RECEIVE_ITEM_STATUS"
      };
      masterTypeService
      .listMaster(params)
      .then((res: ResponseListMasterType[]) => {
        const sorted: {
          key: number,
          value: string,
          id: string,
          name: string
        }[] = [];
        res.forEach(item => {
          sorted.push({
            key: this.sortStatus(item.value),
            value: item.value,
            id: item.id,
            name: item.name,
          });
        });
        sorted.sort((a, b) => a.key - b.key);
        this.dtStatus = sorted;
      });
    },
    /**
     * get list good receipt
     * #warehouse team
     */
    getListGoodReceiptWarehouse(params: RequestQueryParamsModel): void {
      this.loading.find = true;
      receiveItemChecklistService.listInventoryReceiveItem(params)
      .then(res => {
        res.data.forEach(item => item["key"] = item.id);
        this.dtGoodReceiptWarehouse = res;
        this.setTableFilter(res.totalElements, params);
      }).finally(() => {
        this.loading.find = false;
      });
    },
    /**
     * get list good receipt
     * #purchasing team
     */
    getListGoodReceiptPurchasing(params: RequestQueryParamsModel): void {
      this.loading.find = true;
      receiveItemsService
      .listOfReceivingItems(params)
      .then(res => {
        res.data.forEach(item => item["key"] = item.id); // should give key per row
        this.dtGoodReceiptPurchasing = res;
        this.setTableFilter(res.totalElements, params);
      }).finally(() => {
        this.loading.find = false;
      });
    },
    /**
     * set table filter for
     * download param
     */
    setTableFilter(totalElements: number, params: RequestQueryParamsModel): void {
      this.tableFilter = {
        limit: totalElements,
        page: 0,
        search: params.search || ""
      };
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.grParam.limit = response.size;
      this.grParam.page = 0;
      this.requestDtSource(this.grParam);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.grParam.page = response.page - 1;
      this.requestDtSource(this.grParam);
    },
    findData(): void {
      const params: RequestQueryParamsModel = {
        limit: this.grParam.limit,
        page: 0,
        sorts: "createdDate:desc"
      };
      let arrSearch: string[] = [];
      let searchParams = "";
      for (const key in this.formModel) {
        if (Object.prototype.hasOwnProperty.call(this.formModel, key)) {
          const element = this.formModel[key];
          if (key === "branchName" && element) {
            arrSearch.push(
                `_AND_branch.secureId~${element}`
            );
          }
          if (key === "supplierName" && element) {
            arrSearch.push(
              `_AND_supplier.firstName~*${element.replace(
                /,/g,
                "\""
              )}*_OR_supplier.lastName~*${element.replace(
                /,/g,
                "\""
              )}*`
            );
          }
          if (key === "poNumber" && element) {
            arrSearch.push(
              `_AND_purchaseOrderNumber~${element}`
            );
          }
          if (key === "goodReceiptNumber" && element) {
            arrSearch.push(
              `_AND_checklistNumber~${element}`
            );
          }
          if (key === "goodReceiptDateFrom" && element) {
            arrSearch.push(
              `_AND_receiveDate>=${this.moment(
                element
              ).set({
                hour: 0,
                minute: 0,
                second: 0
              }).format()}`
            );
          }
          if (key === "goodReceiptDateTo" && element) {
            arrSearch.push(
              `_AND_receiveDate<=${this.moment(
                element
              ).set({
                hour: 23,
                minute: 59,
                second: 59
              })
              .format()}`
            );
          }
          if (key === "status" && element) {
            arrSearch.push(`_AND_status~${element.replace(/\s/gi, "_").toUpperCase()}`);
          }
        }
      }

      if (arrSearch.length > 0) {
        searchParams = arrSearch.join().slice(5, arrSearch.join().length);
      }

      if (searchParams){
        params.search = searchParams.replace(/,/g, "").replace(/"/g, ",");
      }

      this.requestDtSource(params);
    },
    clearFilter(): void {
      for (const key in this.formModel) {
        if (Object.prototype.hasOwnProperty.call(this.formModel, key)) {
          this.formModel[key] = "";
        }
      }
    },
    handleDownloadReceiveItem(): void {
      this.loading.download = true;
      receiveItemsService.downloadReportReceivingItems(this.tableFilter)
      .then((res: ArrayBuffer) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Report_GoodReceipt.xlsx"); //or any other extension
        link.click();
      })
      .catch(() => {
        this.$message.error(this.$t("notif_download_error").toString());
      })
      .finally(() => this.loading.download = false);
    },
    handleDownloadReceiveItemChecklist(): void {
      this.loading.download = true;
      receiveItemChecklistService.downloadReportReceivingItemChecklist(this.tableFilter)
      .then((res: ArrayBuffer) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Report_GoodReceiptChecklist.xlsx"); //or any other extension
        link.click();
      })
      .catch(() => {
        this.$message.error(this.$t("notif_download_error").toString());
      })
      .finally(() => this.loading.download = false);
    },
    download(): void {
      if (this.hasPrivilegeInventoryReceive) {
        this.handleDownloadReceiveItem();
      } else {
        this.handleDownloadReceiveItemChecklist();
      }
    },
    print(): void {
      const { tableSource } = this;

      tableSource.data.forEach(item => {
        if (!item.supplierName) {
          item.supplierName = "-";
        }
        if (!item["grChecklistNumber"]) {
          item["grChecklistNumber"] = "-";
        }
        if (!item.journalNumber) {
          item.journalNumber = "-";
        }
        if (!item.invoiceNumber) {
          item.invoiceNumber = "-";
        }
        if (item.receiveDate) {
          item.receiveDate = moment(item.receiveDate).format(`${DEFAULT_DATE_FORMAT}, h:mm a`);
        }
      });

      const newdt = tableSource.data;
      // privilege receive-item
      let properties: string[] = [
        "supplierName",
        "purchaseOrderNumber",
        "grChecklistNumber",
        "receiveNumber",
        "journalNumber",
        "receiveDate",
        "invoiceNumber",
        "status"
      ];
      // privilege good-receipt-warehouse
      if (this.hasPrivilegeGRWarehouse) {
        properties = [
          "supplierName",
          "purchaseOrderNumber",
          "grChecklistNumber",
          "receiveDate",
          "status"
        ];
      }
      printJS({
        printable: newdt,
        properties,
        type: "json",
        gridHeaderStyle: "border: 1px solid #000",
        gridStyle: "text-align: center;border: 1px solid #000",
        onError: (error) => {
          this.$notification.error({
            message: this.$t("lbl_error_title").toString(),
            description: error.message
          });
        }
      });
    },
    createNew(): void {
      this.$router.push({name: "logistic.receivingitems.grchecklist.create"});
    },
    requestDtSource(params: RequestQueryParamsModel): void {
      if (this.hasPrivilegeGRWarehouse && this.hasPrivilegeInventoryReceive) {
        this.getListGoodReceiptPurchasing(params);
      } else if (this.hasPrivilegeGRWarehouse) {
        this.getListGoodReceiptWarehouse(params);
      } else if (this.hasPrivilegeInventoryReceive) {
        this.getListGoodReceiptPurchasing(params);
      }
    },
    goToView(id: string): void {
      this.$router.push({ name: "logistic.receivingitems.grchecklist.view", params: { id } });
    },
    goToUpdate(id: string): void {
      sessionStorage.removeItem(GR_LOCAL_STORAGE.GR_PURCHASE_FORM);
      this.$router.push({ name: "logistic.receivingitems.edit", params: { id } });
    }
  }
});
